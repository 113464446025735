import React from "react";
import "./Clouds.css";
import "./Birds.css";

const Clouds = () => {
    return (
        <>
            <div id="clouds">
                <div className="cloud x1"></div>
                <div className="cloud x2"></div>
                <div className="cloud x3"></div>
                <div className="cloud x4"></div>
                <div className="cloud x5"></div>
            </div>
            <div id="birds">
                <div className="bird bird1"></div>
            </div>
        </>
    );
}

export default Clouds;
