import React from "react";
import "./title.css";

const Title = () => {
    return (
        <>
            <h1><span>Hi</span><span> i´m davamo developer</span></h1>
        </>
    );
}

export default Title;
