import React, { useRef, useEffect, useState } from "react";
import "./styles.css";
import { motion } from "framer-motion";
import Next from './components/Next/Next';
import Title from './components/Title/Title';
import IconLinkedin from './assets/logos/icon-linkedin.svg';
import IconEmail from './assets/logos/icon-email.svg';
import IconUnity from './assets/logos/icon-unity.svg';
import IconCsharp from './assets/logos/icon-csharp.svg';
import IconLinuxtux from './assets/logos/icon-linuxtux.svg';
import IconJavascript from './assets/logos/icon-javascript.svg';
import IconReact from './assets/logos/icon-react.svg';
import IconCss from './assets/logos/icon-css.svg';
import IconHtml5 from './assets/logos/icon-html5.svg';
import IconAngular from './assets/logos/icon-angular.svg';
import IconNodejs from './assets/logos/icon-nodejs.svg';
import IconPython from './assets/logos/icon-python.svg';
import IconPhp from './assets/logos/icon-php.svg';
import IconVue from './assets/logos/icon-vue.svg';
import IconMongodb from './assets/logos/icon-mongodb.svg';
import IconMysql from './assets/logos/icon-mysql.svg';
import IconPostgres from './assets/logos/icon-postgres.svg';
import IconGit from './assets/logos/icon-git.svg';
import IconDocker from './assets/logos/icon-docker.svg';
import IconAws from './assets/logos/icon-aws.svg';
import Clouds from './components/Clouds/Clouds';

export default function App() {
  const outerRef = useRef(null);
  const [inViewport, setInViewport] = useState(true);

  useEffect(() => {
    const onChange = entries => {
      entries.forEach(entry => {
        if (entry.target === outerRef.current) {
          if (entry.isIntersecting) {
            setInViewport(true);
          } else {
            setInViewport(false);
          }
        }
      });
    };
    const observer = new IntersectionObserver(onChange, { threshold: 0.5 });
    observer.observe(outerRef.current);
  }, [outerRef]);

  // Framer motion animations
  const fadeInContainerWithStagger = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.25,
        type: "tween",
        ease: "easeIn",
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const fadeInUp = {
    hidden: {
      opacity: 0,
      y: 40
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring"
      }
    }
  };

  return (
    <>
      <div id="previous-content">
        <div><Clouds/></div>

        <div><Title /></div>
        <div><Next /></div>
        <div>
         <a href="https://www.linkedin.com/in/davamo/"> <img src={IconLinkedin}  style={{ "width": "30px", "margin": "10px 10px 10px 10px" }} alt="" /></a> 
         <a href="mailto:davamo@davamo.cl"> <img src={IconEmail}  style={{ "width": "30px", "margin": "10px 10px 15px 10px" }} alt="" /></a> 
        </div>
      </div>

      <div ref={outerRef} id="outer-box">
        {inViewport && (
          <motion.div
            variants={fadeInContainerWithStagger}
            initial="hidden"
            animate="visible"
          >


            <motion.div variants={fadeInUp}>
              skill´s
            </motion.div>

            <motion.div variants={fadeInUp}>
            <img src={IconUnity} style={{ "width": "60px", "margin": "10px 10px 10px 10px" }}  alt="" />
            <img src={IconCsharp} style={{ "width": "60px", "margin": "10px 10px 10px 10px" }}  alt="" />
            <img src={IconLinuxtux} style={{ "width": "60px", "margin": "10px 10px 10px 10px" }}  alt="" />
            </motion.div>

            <motion.div variants={fadeInUp}>
            <img src={IconNodejs} style={{ "width": "60px", "margin": "10px 10px 10px 10px" }}  alt="" />
            <img src={IconPython} style={{ "width": "60px", "margin": "10px 10px 10px 10px" }}  alt="" />
            <img src={IconPhp} style={{ "width": "70px", "margin": "10px 10px 18px 10px" }}  alt="" />
            </motion.div>

            <motion.div variants={fadeInUp}>
            </motion.div>

            <motion.div variants={fadeInUp}>
              <img src={IconHtml5} style={{ "width": "63px", "margin": "10px 10px 10px 10px" }} alt="" />
              <img src={IconCss}  style={{ "width": "70px", "margin": "10px 10px 10px 10px" }} alt="" />
              <img src={IconJavascript} style={{ "width": "60px", "margin": "10px 10px 10px 10px" }} alt="" />

            </motion.div>

            <motion.div variants={fadeInUp}>
              <img src={IconReact}  style={{ "width": "60px", "margin": "10px 10px 10px 10px" }} alt="" />
              <img src={IconAngular} style={{ "width": "60px", "margin": "10px 10px 10px 10px" }} alt="" />
              <img src={IconVue} style={{ "width": "60px", "margin": "10px 10px 10px 10px"  }} alt="" />
            </motion.div>

            <motion.div variants={fadeInUp}>
            <img src={IconMongodb} style={{ "width": "80px" }} alt="" />
            <img src={IconMysql} style={{ "width": "80px", "margin": "10px 10px 10px 10px"  }} alt="" />
            <img src={IconPostgres} style={{ "width": "60px", "margin": "10px 10px 10px 10px"  }} alt="" />
            </motion.div>

            <motion.div variants={fadeInUp}>
            <img src={IconGit} style={{ "width": "60px" }} alt="" />
            <img src={IconDocker} style={{ "width": "80px", "margin": "10px 10px 10px 10px"  }} alt="" />
            <img src={IconAws} style={{ "width": "60px", "margin": "10px 10px 10px 10px"  }} alt="" />
            </motion.div>

          </motion.div>
        )}
      </div>
    </>
  );
}
